import React from "react";

interface Props
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "className"> {
  children: React.ReactNode;
  variant?: "primary" | "secondary";
}

export default function Button({ variant = "primary", ...props }: Props) {
  if (variant === "primary")
    return (
      <button
        {...props}
        className="flex justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
      />
    );
  else if (variant === "secondary")
    return (
      <button
        {...props}
        className="flex justify-center items-center py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
      />
    );
  return null;
}
