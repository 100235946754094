import { useQuery } from "react-query";
import getLeaderboardData from "src/api/leaderboard";
import Table from "src/components/Table";
import { useAdminToken } from "src/hooks";
import AdminTokenForm from "src/modules/AdminTokenForm";

export default function LeaderboardPage() {
  const { adminToken } = useAdminToken();

  const {
    data: leaderboardData,
    isLoading,
    isError,
    isRefetching,
    isRefetchError,
  } = useQuery({
    queryKey: ["leaderboard"],
    queryFn: () => getLeaderboardData(adminToken),
    enabled: !!adminToken,
    refetchInterval: 5 * 1000,
  });

  if (adminToken) {
    return (
      <div className="w-full py-8 px-24 flex flex-col gap-8">
        <h1 className="text-4xl font-bold text-center">Ranking</h1>
        {(isError || isRefetchError) && (
          <p className="text-red-500 text-center">
            Nie udało się pobrać danych rankingu
          </p>
        )}
        <div className="w-full">
          <Table
            headers={["Team", "Score"]}
            rows={[...(leaderboardData || [])].map((team) => [
              team.name,
              team.competition_points,
            ])}
          />
        </div>
        {(isLoading || isRefetching) && (
          <p className="text-center">Właśnie ładuję ranking...</p>
        )}
      </div>
    );
  } else {
    return <AdminTokenForm />;
  }
}
