import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  fetchChallenges,
  interactWithChallenge,
  resetChallengeInteractions,
  submitChallengeSolution,
} from "src/api/challenges";
import Challenge from "src/modules/Challenge";
import { getCurrentTeam } from "src/api/team";
import Table from "src/components/Table";

export default function ChallengesPage() {
  const searchParams = new URLSearchParams(window.location.search);
  const teamToken = searchParams.get("teamToken") || "";
  const queryClient = useQueryClient();
  const [isSolutionLoadingForId, setIsSolutionLoadingForId] = useState<
    number | null
  >(null);
  const [isInteractionLoadingForId, setIsInteractionLoadingForId] = useState<
    number | null
  >(null);
  const [isInteractionResetLoadingForId, setIsInteractionResetLoadingForId] =
    useState<number | null>(null);
  const [openChallengeId, setOpenChallengeId] = useState<number | null>(null);

  const resetLoadingStates = () => {
    setIsInteractionLoadingForId(null);
    setIsSolutionLoadingForId(null);
    setIsInteractionResetLoadingForId(null);
  };

  const {
    data: team,
    isLoading: isTeamLoading,
    isError: isTeamError,
  } = useQuery({
    queryKey: ["team", teamToken],
    queryFn: () => getCurrentTeam(teamToken),
  });

  const {
    mutateAsync,
    isLoading: isMutationLoading,
    isError: isMutationError,
  } = useMutation({
    mutationFn: ({
      solution,
      challengeId,
    }: {
      solution: string;
      challengeId: number;
    }) => {
      setIsSolutionLoadingForId(challengeId);
      return submitChallengeSolution(teamToken, challengeId, solution);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["challenges"]);
      queryClient.invalidateQueries(["team"]);
      resetLoadingStates();
    },
    onError: () => {
      resetLoadingStates();
    },
  });

  const {
    mutateAsync: resetInteractions,
    isLoading: isResetInteractionsLoading,
    isError: isResetInteractionsError,
  } = useMutation({
    mutationFn: (challengeId: number) => {
      setIsInteractionResetLoadingForId(challengeId);
      return resetChallengeInteractions(teamToken, challengeId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["challenges"]);
      queryClient.invalidateQueries(["team"]);
      resetLoadingStates();
    },
    onError: () => {
      resetLoadingStates();
    },
  });

  const {
    mutateAsync: interact,
    isLoading: interactionLoading,
    isError: isInteractionError,
  } = useMutation({
    mutationFn: ({
      message,
      challengeId,
    }: {
      message: string;
      challengeId: number;
    }) => {
      setIsInteractionLoadingForId(challengeId);
      return interactWithChallenge(teamToken, challengeId, message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["challenges"]);
      queryClient.invalidateQueries(["team"]);
      resetLoadingStates();
    },
    onError: () => {
      resetLoadingStates();
    },
  });

  const { data, isError, isRefetchError, isLoading, isRefetching } = useQuery({
    queryKey: ["challenges", teamToken],
    queryFn: () => fetchChallenges(teamToken),
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });

  return (
    <div className="max-w-screen-xl px-8 flex flex-col gap-8 mx-auto py-8 justify-center">
      {team && (
        <Table
          headers={["Nr", "Drużyna", "Punkty gotówkowe", "Punkty konkursowe"]}
          rows={[
            [team.id, team.name, team.cash_points, team.competition_points],
          ]}
        />
      )}
      {isTeamLoading && <div className="text-center">Ładowanie zespołu...</div>}
      {isTeamError && (
        <div className="text-red-500 text-center">Nie udało się pobrać danych zespołu</div>
      )}
      {data && !isLoading && !isRefetching && (
        <h1 className="text-center text-3xl font-bold">Wyzwania</h1>
      )}
      {isRefetching && <div className="text-center">Odświeżam...</div>}
      {isLoading && <div className="text-center">Ładuję...</div>}
      {(isError ||
        isRefetchError ||
        isMutationError ||
        isInteractionError ||
        isResetInteractionsError) && (
        <div className="text-red-500 text-center">
          Nie udało się pobrać wyzwań
        </div>
      )}
      {[...(data || [])].map((challenge) => (
        <Challenge
          challenge={challenge}
          key={challenge.title}
          onSolutionSubmit={(solution) =>
            mutateAsync({ solution, challengeId: challenge.id })
          }
          onInteractionSubmit={(message) =>
            interact({ message, challengeId: challenge.id })
          }
          onInteractionReset={() => resetInteractions(challenge.id)}
          disableInputs={
            isMutationLoading ||
            interactionLoading ||
            isResetInteractionsLoading ||
            isLoading ||
            isRefetching
          }
          isInteractionLoading={isInteractionLoadingForId === challenge.id}
          isSolutionLoading={isSolutionLoadingForId === challenge.id}
          isInteractionResetLoading={
            isInteractionResetLoadingForId === challenge.id
          }
          open={openChallengeId === challenge.id}
          onToggle={() =>
            setOpenChallengeId((prev) =>
              prev === challenge.id ? null : challenge.id
            )
          }
        />
      ))}
    </div>
  );
}
