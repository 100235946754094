import { Team } from "src/types";

export default async function getLeaderboardData(
  adminToken: string
): Promise<Team[]> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/teams-with-points/`,
    {
      headers: {
        Authorization: `Bearer ${adminToken}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch leaderboard data");
  }
  return response.json();
}
