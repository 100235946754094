import { useEffect, useRef } from "react";
import ReactMarkdown from 'react-markdown';
import Accordion from "src/components/Accordion";
import TextArea from "src/components/TextArea";
import { type Challenge as ChallengeType } from "src/types";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import { cn } from "src/utils";

interface Props {
  challenge: ChallengeType;
  onSolutionSubmit: (solution: string) => Promise<void>;
  onInteractionSubmit: (message: string) => Promise<string>;
  onInteractionReset: () => Promise<void>;
  disableInputs: boolean;
  isInteractionLoading: boolean;
  isInteractionResetLoading: boolean;
  isSolutionLoading: boolean;
  open: boolean;
  onToggle: () => void;
}

export default function Challenge({
  challenge,
  onSolutionSubmit,
  onInteractionSubmit,
  onInteractionReset,
  disableInputs,
  isInteractionLoading,
  isInteractionResetLoading,
  isSolutionLoading,
  open,
  onToggle,
}: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const chatTextareaRef = useRef<HTMLTextAreaElement>(null);
  const chatButtonsRef = useRef<HTMLDivElement>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    const textarea = ref.current;
    if (!textarea) return;
    textarea.style.height = "auto";
    textarea.style.height = `${Math.max(textarea.scrollHeight, 80)}px`;
  };

  useEffect(() => {
    if (textareaRef.current)
      textareaRef.current.value = challenge.solution?.content || "";
    autoResize(textareaRef);
    autoResize(chatTextareaRef);
  }, [challenge.solution]);

  useEffect(() => {
    chatButtonsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [challenge.interactions.length]);

  return (
    <Accordion
      title={
        <div className="flex gap-4 items-center">
          <div
            className={cn(
              "rounded-full w-3 h-3",
              challenge.solution
                ? challenge.solution.result > 0
                  ? "bg-[#2ecc71]"
                  : "bg-[#f1c40f]"
                : "bg-[#e74c3c]"
            )}
          />
          <h3>{challenge.challenge_type === "cash" ? "💵 " : "🏆 "}{challenge.title}</h3>
        </div>
      }
      open={open}
      onToggle={onToggle}
    >
      <div className="flex flex-col gap-4">
        <ReactMarkdown>{challenge.description}</ReactMarkdown>
        {challenge.is_interactive && (
          <>
            <h3 className="text-center text-xl font-bold">Chat z AI</h3>
            <div className="flex flex-col gap-4">
              {challenge.interactions.map(({ message, role, id }) => (
                <p
                  className={cn(
                    "bg-gray-200 p-2 rounded-md break-words max-w-[90%]",
                    role === "assistant" ? "self-start" : "self-end"
                  )}
                  key={id}
                >
                  {message}
                </p>
              ))}
              <form
                className="flex flex-col gap-4"
                onSubmit={async (e) => {
                  e.preventDefault();
                  if (!chatTextareaRef.current || disableInputs) return;
                  const message = chatTextareaRef.current.value || "";
                  chatTextareaRef.current.value = "";
                  challenge.interactions.push({
                    id: 0,
                    message,
                    role: "user",
                  });
                  await onInteractionSubmit(message);
                }}
              >
                <TextArea
                  ref={chatTextareaRef}
                  name="message"
                  onInput={() => autoResize(chatTextareaRef)}
                  required
                  disabled={disableInputs}
                />
                <div className="flex justify-end gap-3" ref={chatButtonsRef}>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={async () => await onInteractionReset()}
                    disabled={disableInputs}
                  >
                    {isInteractionResetLoading ? (
                      <Loader variant="secondary" />
                    ) : (
                      "Od nowa"
                    )}
                  </Button>
                  <Button type="submit" disabled={disableInputs}>
                    {isInteractionLoading ? <Loader /> : "Wyślij"}
                  </Button>
                </div>
              </form>
            </div>
          </>
        )}
        <form
          className="flex flex-col gap-4"
          onSubmit={async (e) => {
            e.preventDefault();
            if (!textareaRef.current || disableInputs) return;
            const solution = textareaRef.current.value || "";
            await onSolutionSubmit(solution);
            textareaRef.current.value = "";
          }}
        >
          <TextArea
            ref={textareaRef}
            label="Wasze rozwiązanie"
            name="solution"
            onInput={() => autoResize(textareaRef)}
            required
            disabled={disableInputs}
          />
          <Button type="submit" disabled={disableInputs}>
            {isSolutionLoading ? <Loader /> : "Sprawdź"}
          </Button>
        </form>
        {challenge.solution && (
          <div className="bg-gray-100 p-4 rounded-md">
            <p className="font-bold">{challenge.solution.result === 0 ? '❌ Źle' : '✅ Dobrze'}</p>
            {challenge.solution.details && (
              <div className="mt-2">
                <p className="text-sm">{challenge.solution.details}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </Accordion>
  );
}
