import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchTeamCashSolutions } from "src/api/solutions";
import { getTeam } from "src/api/team";
import Table from "src/components/Table";
import { useAdminToken } from "src/hooks";
import AdminTokenForm from "src/modules/AdminTokenForm";

export default function CashChallengesSolutionsPage() {
  const { adminToken } = useAdminToken();

  const { teamId } = useParams<{ teamId: string }>();

  const { data, isError, isRefetchError, isLoading, isRefetching } = useQuery({
    queryKey: ["cash-challenges-solutions", adminToken, teamId],
    queryFn: () => fetchTeamCashSolutions(parseInt(teamId || "0"), adminToken),
    enabled: !!adminToken && !!teamId,
    refetchInterval: 5 * 1000,
  });

  const {
    data: team,
    isError: isTeamError,
    isRefetchError: isTeamRefetchError,
    isLoading: isTeamLoading,
    isRefetching: isTeamRefetching,
  } = useQuery({
    queryKey: ["team", teamId],
    queryFn: () => getTeam(parseInt(teamId || "0"), adminToken),
    enabled: !!adminToken && !!teamId,
    refetchInterval: 5 * 1000,
  });

  if (adminToken) {
    return (
      <div className="w-full py-8 px-24 flex flex-col gap-8">
        <h1 className="text-4xl font-bold text-center">
          Dane rozwiązań wyzwań gotówkowych
        </h1>
        {(isError || isRefetchError || isTeamError || isTeamRefetchError) && (
          <p className="text-red-500 text-center">
            Nie udało się pobrać danych rozwiązań wyzwań gotówkowych
          </p>
        )}
        <Table
          headers={["Nazwa drużyny", "Punkty gotówkowe", "Punkty konkursowe"]}
          rows={[[team?.name, team?.cash_points, team?.competition_points]]}
        />
        <div className="w-full">
          <Table
            headers={["ID wyzwania", "Wynik", "Czas"]}
            rows={(data || []).map((solution) => [
              solution.challenge_id,
              solution.result,
              new Date(solution.modified_at).toLocaleTimeString(),
            ])}
          />
        </div>
        {(isLoading || isRefetching || isTeamLoading || isTeamRefetching) && (
          <p className="text-center">
            Ładowanie danych rozwiązań wyzwań gotówkowych...
          </p>
        )}
      </div>
    );
  } else {
    return <AdminTokenForm />;
  }
}
