import { type Solution } from "src/types";

export async function fetchTeamCashSolutions(
  teamId: number,
  adminToken: string
): Promise<Solution[]> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/solutions/?team_id=${teamId}&challenge_type=cash`,
    {
      headers: {
        Authorization: `Bearer ${adminToken}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch team cash solutions");
  }
  return response.json();
}
