import Button from "src/components/Button";
import TextField from "src/components/TextField";
import { useAdminToken } from "src/hooks";

export default function AdminTokenForm() {
  const { setAdminToken } = useAdminToken();
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <form
        className="flex flex-col gap-4 w-96"
        onSubmit={(e) => {
          e.preventDefault();
          const token = (
            e.target as typeof e.target & { token: { value: string } }
          ).token.value;
          setAdminToken(token);
          window.location.reload();
        }}
      >
        <TextField
          name="token"
          label="Admin token"
          placeholder="Enter token"
          required
        />
        <Button type="submit">OK</Button>
      </form>
    </div>
  );
}
