import { Challenge } from "src/types";

export async function fetchChallenges(teamToken: string): Promise<Challenge[]> {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/challenges/`, {
    headers: {
      Authorization: `Bearer ${teamToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch challenges");
  }
  const data = await response.json();
  return data;
}

export async function submitChallengeSolution(
  teamToken: string,
  challengeId: number,
  solution: string
): Promise<void> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/challenges/${challengeId}/submit/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${teamToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ solution }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to submit solution");
  }
}

export async function interactWithChallenge(
  teamToken: string,
  challengeId: number,
  message: string
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/challenges/${challengeId}/interact/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${teamToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to interact with challenge");
  }
  const data: { response: string } = await response.json();
  return data.response;
}

export async function resetChallengeInteractions(
  teamToken: string,
  challengeId: number
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/challenges/${challengeId}/reset/`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${teamToken}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to reset challenge interactions");
  }
}
