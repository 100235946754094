import React from "react";

interface Props
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "className"> {
  label?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ label, ...props }, ref) => {
    return (
      <div>
        {label && (
          <label
            htmlFor={props.id}
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            {label}
          </label>
        )}
        <textarea
          {...props}
          ref={ref}
          className="resize-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 overflow-hidden"
        ></textarea>
      </div>
    );
  }
);

export default TextArea;
