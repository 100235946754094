import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LeaderboardPage from "./pages/Leaderboard";
import ChallengesPage from "./pages/Challenges";
import { QueryClient, QueryClientProvider } from "react-query";
import CashChallengesSolutionsPage from "./pages/CashChallengesSolutions";
import { useEffect } from "react";

const RedirectToHome = () => {
  useEffect(() => {
    window.location.replace("/");
  }, []);
  return null;
};

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <RedirectToHome />,
    children: [
      {
        path: "",
        element: <LeaderboardPage />,
      },
      {
        path: "challenges",
        element: <ChallengesPage />,
      },
      {
        path: "cash-challenges-solutions/:teamId",
        element: <CashChallengesSolutionsPage />,
      },
    ],
  },
]);

export default function App() {
  const queryClient = new QueryClient({});
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />{" "}
    </QueryClientProvider>
  );
}
