import React from "react";
import AngleUp from "src/icons/AngleUp";
import AngleDown from "src/icons/AngleDown";

interface Props {
  children: React.ReactNode;
  title: React.ReactNode;
  open: boolean;
  onToggle: () => void;
}

export default function Accordion({ children, title, open, onToggle }: Props) {
  return (
    <div>
      <h2>
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-[2px]border-gray-200 rounded-md focus:ring-4 focus:ring-gray-200 gap-3"
          onClick={onToggle}
        >
          <span>{title}</span>
          {open ? <AngleUp /> : <AngleDown />}
        </button>
      </h2>
      <div
        className={
          open ? "p-6 block border border-gray-100 rounded-md" : "hidden"
        }
      >
        {children}
      </div>
    </div>
  );
}
