import { useState } from "react";

export function useAdminToken() {
  const [adminToken, _setAdminToken] = useState(
    sessionStorage.getItem("adminToken") || ""
  );

  const setAdminToken = (token: string) => {
    sessionStorage.setItem("adminToken", token);
    _setAdminToken(token);
  };

  return {
    adminToken,
    setAdminToken,
  };
}
