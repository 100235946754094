import React from "react";

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "className"> {
  label: string;
}

export default function TextField({ label, ...props }: Props) {
  return (
    <div>
      <label
        htmlFor={props.id}
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
      <input
        {...props}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
    </div>
  );
}
