import { Team } from "src/types";

export async function getCurrentTeam(teamToken: string): Promise<Team> {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/my-team`, {
    headers: {
      Authorization: `Bearer ${teamToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to get current team");
  }
  return response.json();
}

export async function getTeam(
  teamId: number,
  adminToken: string
): Promise<Team> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/teams/${teamId}`,
    {
      headers: {
        Authorization: `Bearer ${adminToken}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to get team");
  }
  return response.json();
}
