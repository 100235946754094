import React from "react";

interface Props {
  headers: React.ReactNode[];
  rows: React.ReactNode[][];
}

export default function Table({ headers, rows }: Props) {
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 table-fixed">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {headers.map((header, index) => (
              <th scope="col" className="px-6 py-3" key={index}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr className="bg-white border-b" key={index}>
              {row.map((cell, cellIndex) => (
                <td
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  key={cellIndex}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
